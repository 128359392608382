import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ContextState } from '../../../context';
import { InsertSeparator } from "../../../lib";
import { Column, DataTable } from '../../organisms/DataTable';
import { CampaignProgresses } from './useRewardList';

interface RewardListTableProps {
	isCoupon?: boolean;
	rows?: APIResponses.GetRewardsResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
	state: ContextState;
	campaignMap?: Map<string, CampaignProgresses>
	toRewardEditURL: (rewardId: string) => string,
	toRewardCopyURL: (rewardId: string) => string,
}

export const RewardListTable: React.FunctionComponent<RewardListTableProps> = (props) => {
	const { state, campaignMap, toRewardEditURL, toRewardCopyURL } = props;

	// テーブル固有のCSS指定
	const tableStyle = css`
		height: 632px;
	`;

	const quantityBodyTemplate = (rowData: any) => {
		if (rowData.isCoupon) {
			return '';
		}
		return rowData.isUnlimited ? '無制限' : rowData.quantity;
	};
	const imageBodyTemplate = (rowData: any) => {
		if (!rowData.isCoupon) {
			if (rowData.image) {
				return <img src={`/storage/${state.params.shopId}/${rowData.image}`} />;
			}
		} else if (rowData.isCoupon) {
			if (rowData.couponImageType == 'template' || rowData.couponImageType == 'upload') {
				// TODO: 可能ならyahooにアップロードされた画像URLを設定する
				return <img src={`/storage/${state.params.shopId}/${rowData.couponImageUpload}`} />;
			}
		}
		return null;
	};
	const campaignBodyTemplate = (rowData: any) => {
		return <>
			{campaignMap &&
				<table className="bl_nestTable">
					<tbody>
						<tr>
							<th>実施前</th>
							<td>
								<InsertSeparator>
									{campaignMap.get(rowData.id)?.ready}
								</InsertSeparator>
							</td>
						</tr>
						<tr>
							<th>実施中</th>
							<td>
								<InsertSeparator>
									{campaignMap.get(rowData.id)?.inProgress}
								</InsertSeparator>
							</td>
						</tr>
					</tbody>
				</table>
			}
			<div className="bl_table_btnWrap">
				<Link className="el_btnInv" type="button" to={toRewardEditURL(rowData.id)}>編集</Link>
				<Link className="el_btnInv" type="button" to={toRewardCopyURL(rowData.id)}>複製</Link>
			</div>
		</>;
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};

	const nameHeader = props.isCoupon ? 'クーポン名' : 'プレゼント品名';
	const nameStyleWidth = props.isCoupon ? '480px' : '406px';

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 48px center center" field="id" listSelector></Column>
				<Column style={`1 1 ${nameStyleWidth} center flex-start`} field="name">{nameHeader}</Column>
				{!props.isCoupon &&
					<Column style="0 0 74px center center" field="quantity" bodyTemplate={quantityBodyTemplate}>在庫数</Column>
				}
				<Column style="0 0 112px center center" field="image" bodyTemplate={imageBodyTemplate}>画像</Column>
				<Column style="1 0.3 896px center center" tdClassName="bl_nestTableWrap" field="campaign" bodyTemplate={campaignBodyTemplate}>登録されているキャンペーン</Column>
			</DataTable>
		</>
	);
}
export default RewardListTable;