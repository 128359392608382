export const PAGE_TITLES = new Map([
	["/reward/target", "レビュー待ち状況"],
	["/reward/request", "特典申込受付"],
	["/reward/reward", "レビュー特典登録"],
	["/reward/group", "対象商品グループ登録"],
	["/reward/campaign", "キャンペーン登録"],
	["/mail/smtp-setting", "SMTPサーバ設定"],
	["/mail/setting", "メール送信設定"],
	["/mail/template", "送信メールテンプレート"],
	["/mail/not-covered", "特典対象外商品用メール"],
	["/mail/exclusion", "メール送信除外設定"],
	["/setting/user", "ユーザーアカウント設定"],	// 楽天版と異なる
	["/account/yahoo-setting", "Yahoo!システム設定"],	// 楽天版と異なる
	["/account/shop-setting", "店舗設定"],
	["/account/system-alert", "システムアラート設定"],
	["/management", "ユーザー管理"]
]);

export const NOT_LOGIN_PAGE_TITLES = new Map([
	["/account/recover", "パスワード再設定"],
]);