import * as React from 'react';
import { Link } from 'react-router-dom';
import GuideNavigationIndex from "./GuideNavigationIndex";

export interface GuideNavigationProps {
	path: string,
	paramPath: string,
	folderPath: string,
	isMarkdown: boolean,
}

export const GuideNavigation: React.FunctionComponent<GuideNavigationProps> = (props) => {
	const [isNaviTopActive, setIsNaviTopActive] = React.useState<boolean>(true);
	const [isSettingActive, setIsSettingActive] = React.useState<boolean>(true);
	const [isUseGuideActive, setIsUseGuideActive] = React.useState<boolean>(true);

	const togleChange = (value: boolean, setFunc: (setVal:boolean) => void) => {
		setFunc(!value);
	};

	return (
		<div className="bl_panel bl_panel__bt bl_panel__guide_menu">
			<ul className="bl_guideNav">
				<li className={isNaviTopActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isNaviTopActive, setIsNaviTopActive)}>利用ガイドTOP</p>
					<ul className="bl_guideNav_list">
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/use_guide/top"} className={"use_guide/top" == props.paramPath ? 'selectGuide' : ''}>TOP</Link>
							<GuideNavigationIndex naviPath='use_guide/top' {...props} />
						</li>
					</ul>
				</li>
				<li className={isSettingActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isSettingActive, setIsSettingActive)}>登録・設定方法</p>
					<ul className="bl_guideNav_list">
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/use_flow"} className={"registration_setting/use_flow" == props.paramPath ? 'selectGuide' : ''}>はじめての方へ</Link>
							<GuideNavigationIndex naviPath='registration_setting/use_flow' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/preparation"} className={"registration_setting/preparation" == props.paramPath ? 'selectGuide' : ''}>事前準備</Link>
							<GuideNavigationIndex naviPath='registration_setting/preparation' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/yahoo_system_setting"} className={"registration_setting/yahoo_system_setting" == props.paramPath ? 'selectGuide' : ''}>Yahooシステム設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/yahoo_system_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/shop_setting"} className={"registration_setting/shop_setting" == props.paramPath ? 'selectGuide' : ''}>店舗設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/shop_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/mail_setting"} className={"registration_setting/mail_setting" == props.paramPath ? 'selectGuide' : ''}>メール設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/mail_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/from_review_privilege_up_to_campaign_register"} className={"registration_setting/from_review_privilege_up_to_campaign_register" == props.paramPath ? 'selectGuide' : ''}>レビュー特典〜キャンペーン登録</Link>
							<GuideNavigationIndex naviPath='registration_setting/from_review_privilege_up_to_campaign_register' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/user_account_setting"} className={"registration_setting/user_account_setting" == props.paramPath ? 'selectGuide' : ''}>ユーザーアカウント設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/user_account_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/password_setting"} className={"registration_setting/password_setting" == props.paramPath ? 'selectGuide' : ''}>パスワード設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/password_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/system_alert"} className={"registration_setting/system_alert" == props.paramPath ? 'selectGuide' : ''}>システムアラート設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/system_alert' {...props} />
						</li>
					</ul>
				</li>
				<li className={isUseGuideActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isUseGuideActive, setIsUseGuideActive)}>操作ガイド</p>
					<ul className="bl_guideNav_list">
						{/* <li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/top"} className={"operation/top" == props.paramPath ? 'selectGuide' : ''}>TOP</Link>
							<GuideNavigationIndex naviPath='operation/top' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/review_privilege"} className={"operation/review_privilege" == props.paramPath ? 'selectGuide' : ''}>レビュー特典</Link>
							<GuideNavigationIndex naviPath='operation/review_privilege' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/mail_setting"} className={"operation/mail_setting" == props.paramPath ? 'selectGuide' : ''}>メール設定</Link>
							<GuideNavigationIndex naviPath='operation/mail_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/various_setup"} className={"operation/various_setup" == props.paramPath ? 'selectGuide' : ''}>各種設定</Link>
							<GuideNavigationIndex naviPath='operation/various_setup' {...props} />
						</li> */}
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/csv_format"} className={"operation/csv_format" == props.paramPath ? 'selectGuide' : ''}>送り先CSV形式</Link>
							<GuideNavigationIndex naviPath='operation/csv_format' {...props} />
						</li>
					</ul>
				</li>
			</ul>

			{/* <a className="el_btnWideBorderInv" href={props.folderPath + "/top"}>よくあるご質問</a> */}
		</div>
	);
};
export default GuideNavigation;