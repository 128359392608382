import { User } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { loginMessageSetter, loginSetter, useAPI, useAppState, userSetter } from "../../../context";

type FormValues = {
	mail: string;
	password: string;
};

export const useLogin = (initValues: FormValues) => {
	const [state, setState] = useAppState();
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const [shakeMessage, setShakeMessage] = React.useState(0);

	React.useEffect(() => {
		if ('user' in state) {
			return;
		}

		return callAPI.user.userLoginBySession({}, (err, result) => {
			if (err) {
				return;
			}
			const user = new User(result.data.user as Record<string, unknown>);
			setState([userSetter(user), loginSetter(null)]);
		});
	}, []);

	const handleClickLogin: SubmitHandler<FormValues> = async (values) => {
		try {
			const result = await callAPI.user.userLogin({
					mail: values.mail,
					pass: values.password
				});
			if (result && result.data) {
				const user = new User(result.data.user as Record<string, unknown>);
				setState([userSetter(user), loginSetter(null)]);
			}
		} catch (err) {
			setShakeMessage((p) => p + 1);
			if (err.status === 429) {
				setState(loginMessageSetter('短時間に多数のログインに失敗したため、ログインが制限されています。'));
			} else if (err.data === 'MISMATCH') {
				setState(loginMessageSetter('メールアドレスかパスワードが違います'));
			} else if (err.data === 'INVALID_USER') {
				setState(loginMessageSetter('利用可能な店舗がありません。'));
			} else {
				// 上記以外のエラーの場合
				setState(loginMessageSetter('アカウント認証に失敗しました'));
			}
		}
	};

	return {
		state,
		methods: formMethods,
		handleClickLogin,
		shakeMessage,
	};
};
export default useLogin;