import ja from 'date-fns/locale/ja';
import * as React from 'react';
import { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import TimePicker from './TimePicker';
registerLocale('ja', ja);

const DEFAULT_POPPER_CLASSNAME = "datePickerPopper";

export type DateFields = Date | number | string;

export function toDate(fields: DateFields): Date {
	if (!(fields instanceof Date)) {
		fields = new Date(parseInt(fields.toString(), 10));
	}
	return fields;
}

export function toDateFields(date?: Date | number): Date|"" {
	const checkedDate = date ? new Date(date) : "";
	return checkedDate;
}

interface InputDateProps {
	mode: 'date' | 'time' | 'dateTime';
	name: string;
	rules?: Record<string, unknown>;
	onChange?: () => void;
	onBlur?: () => void;
	popperClassName?: string;
	minDate?: Date;
	isClearable?: boolean;
	disabled?: boolean;
}
export const InputDate: React.FC<InputDateProps> = (props) => {
	const { control } = useFormContext();

	const extendOnChange = (baseOnChange: (...event: any[]) => void) => {
		return (event: any[]) => {
			baseOnChange(event);
			props.onChange && props.onChange();
		};
	};
	const extendOnBlur = (baseOnBlur: () => void) => {
		return () => {
			baseOnBlur();
			props.onBlur && props.onBlur();
		};
	};

	const popperClassName = props.popperClassName ?? DEFAULT_POPPER_CLASSNAME;

	return (
		<Controller
			control={control}
			rules={props.rules}
			name={props.name}
			render={({ onChange, onBlur, value }) => (
				<>
					{props.mode == 'date' &&
						<DatePicker
							onChange={extendOnChange(onChange)}
							onBlur={extendOnBlur(onBlur)}
							value={value}
							popperClassName={popperClassName}
							minDate={props.minDate}
							isClearable={props.isClearable}
							/>
					}
					{props.mode == 'time' &&
						<TimePicker
							onChange={extendOnChange(onChange)}
							value={value}
							popperClassName={popperClassName}
							/>
					}
					{props.mode == 'dateTime' &&
						<DateTimePicker
							onChange={extendOnChange(onChange)}
							onBlur={extendOnBlur(onBlur)}
							value={value}
							popperClassName={popperClassName}
							minDate={props.minDate}
							disabled={props.disabled}
							/>
					}
				</>
			)}
		/>
	);
}
export default InputDate;
