import * as React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Loading } from '../../atoms/Loading';
import { ColumnProps } from './Column';
import { contextListSelector } from './ListSelectorContext';

interface DataTableBodyTdProps {
	/** カラム情報 */
	columnProps: ColumnProps[];
	/** 行表示用データ */
	row: any;
	/** 行インデックス値 */
	idx: number;
	/** 一覧表示用データの主キー */
	dataKey: string;
	/** 読み込み中フラグ */
	isLoading: boolean;
	/** 並び替え用引数 */
	provided?: DraggableProvided;
}

export const DataTableBodyTd: React.FunctionComponent<DataTableBodyTdProps> = (props) => {
	const context = React.useContext(contextListSelector);

	if (props.isLoading) {
		return (
			<>
				{props.columnProps.map((column) => <td key={`${props.row[props.dataKey]}__${column.field}`}><Loading /></td>)}
			</>
		);
	}

	return (
		<>
			{props.columnProps.map((column) =>
				<td key={`${props.row[props.dataKey]}__${column.field}`}
					className={column.tdClassNameTemplate ? column.tdClassNameTemplate(props.row) : column.tdClassName}>
					{(column.rowReorder && props.provided) && <>
						<div className="el_dragIndicator" {...props.provided.dragHandleProps}></div>
					</>}
					{(column.listSelector && context) && <>
						<input type="checkbox" className="el_checkMark" name={`check.${props.row[props.dataKey]}`} ref={context.register} onChange={context.handleChange} />
					</>}
					{!column.listSelector && <>
						{column.bodyTemplate && column.bodyTemplate(props.row)}
						{!column.bodyTemplate && props.row[column.field]}
					</>}
				</td>
			)}
		</>
	);
}
export default DataTableBodyTd;