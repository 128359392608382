import * as React from 'react';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import YahooIDLinkage from './YahooIDLinkage';
import YahooLogin from './YahooLogin';
import YahooPublicKey from './YahooPublicKey';
import useYahooSetting from './useYahooSetting';

export const YahooSetting: React.FunctionComponent = () => {
	const {
		storedValues,
		token,
		publicKeyMethods,
		yahooLoginMethods,
		handleSavePublicKey,
		handleInitPublicKey,
		handleSaveYahooLogin,
		handleInitYahooLogin
	} = useYahooSetting();

	return (
		<div className="ly_main__yahooSetting">
			<PageTitle>Yahoo!システム設定</PageTitle>
			<PageDesc>Yahoo!システム情報の確認・編集を行うことができます。また、各情報の初期化ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__4">
					<YahooIDLinkage token={token} />
				</div>
				<div className="bl_col bl_col__4">
					<YahooPublicKey
						shop={storedValues}
						methods={publicKeyMethods}
						handleSave={handleSavePublicKey}
						handleInit={handleInitPublicKey}
						/>
				</div>
				<div className="bl_col bl_col__4">
					<YahooLogin
						storedValues={storedValues}
						methods={yahooLoginMethods}
						handleSave={handleSaveYahooLogin}
						handleInit={handleInitYahooLogin}
						/>
				</div>
			</div>
		</div>
	);
};
export default YahooSetting;