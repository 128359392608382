import { yahoo } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { FormValue as YahooLoginFormValue, initValue as YahooLoginInitValue } from './YahooLogin';
import { FormValue as PublicKeyFormValue, initValue as PublicKeyInitValue } from './YahooPublicKey';

export const useYahooSetting = () => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [storedValues, setStoredValues] = React.useState<yahoo.GetShopResponse|null>(null);
	const [token, setToken] = React.useState<yahoo.GetShopTokenExpirationResponse|null>(null);
	const publicKeyMethods = useForm<PublicKeyFormValue>({
		defaultValues: PublicKeyInitValue,
	});
	const yahooLoginMethods = useForm<YahooLoginFormValue>({
		defaultValues: YahooLoginInitValue,
	});

	const toPublicKeySaveAttr = (values: Partial<PublicKeyFormValue>): Partial<yahoo.PutShopRequest> => {
		return {
			publicKey: values.publicKey,
			publicKeyVersion: values.publicKeyVersion,
			publicKeyExpiration: values.publicKeyExpiration ? dayjs(values.publicKeyExpiration).valueOf() : 0,
		};
	};
	const toYahooLoginSaveAttr = (values: Partial<YahooLoginFormValue>): Partial<yahoo.PutShopRequest> => {
		return {
			yahooId: values.yahooId,
			yahooPass: values.yahooPass || undefined,
		}
	};

	const toPublicKeyValues = (shop: yahoo.GetShopResponse): PublicKeyFormValue => {
		return {
			...PublicKeyInitValue,
			publicKey: shop.publicKey,
			publicKeyVersion: shop.publicKeyVersion,
			publicKeyExpiration: shop.publicKeyExpiration ? new Date(shop.publicKeyExpiration) : 0,
		}
	};
	const toYahooLoginValues = (shop: yahoo.GetShopResponse): YahooLoginFormValue => {
		return {
			...YahooLoginInitValue,
			yahooId: shop.yahooId,
		}
	};

	React.useEffect(() => {
		return callAPI.yahoo.getShop({ shopId: state.params.shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			const shop = yahoo.schemaGetShopResponse.parse(result.data);
			setStoredValues(shop);
			publicKeyMethods.reset(toPublicKeyValues(shop));
			yahooLoginMethods.reset(toYahooLoginValues(shop));
		});
	}, [state.params.shopId]);

	React.useEffect(() => {
		if (token) {
			return;
		}

		return callAPI.yahoo.getShopTokenExpiration({ shopId: state.params.shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			const token = yahoo.schemaGetShopTokenExpirationResponse.parse(result.data);
			setToken(token);
		});
	}, [token, state.params.shopId]);

	const resetToken = () => {
		setToken(null);
	};

	const handleSaveShop = async (shopValue: Partial<yahoo.PutShopRequest>): Promise<boolean> => {
		try {
			const params = {
				shopId: state.params.shopId,
				shop: shopValue,
			};
			await callAPI.yahoo.putShop(params);
			return true;
		} catch (err) {
			return false;
		}
	};
	const handleSavePublicKey: SubmitHandler<PublicKeyFormValue> = async (values) => {
		return await handleSaveShop(toPublicKeySaveAttr(values));
	};
	const handleInitPublicKey = async () => {
		const res = await handleSaveShop(PublicKeyInitValue);
		if (res) {
			publicKeyMethods.reset(PublicKeyInitValue);
		}
		return res;
	};
	const handleSaveYahooLogin: SubmitHandler<YahooLoginFormValue> = async (values) => {
		return await handleSaveShop(toYahooLoginSaveAttr(values));
	};
	const handleInitYahooLogin = async () => {
		const res = await handleSaveShop(YahooLoginInitValue);
		if (res) {
			yahooLoginMethods.reset(YahooLoginInitValue);
		}
		return res;
	};

	return {
		storedValues,
		token,
		publicKeyMethods,
		yahooLoginMethods,
		resetToken,
		handleSavePublicKey,
		handleInitPublicKey,
		handleSaveYahooLogin,
		handleInitYahooLogin,
	};
};
export default useYahooSetting;