import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from "../../../context";

export const GUIDE_TITLES = new Map([
	["use_guide/top", "利用ガイド"],
	["registration_setting/use_flow", "登録・設定方法/はじめての方へ"],
	["registration_setting/preparation", "登録・設定方法/事前準備"],
	["registration_setting/yahoo_system_setting", "登録・設定方法/Yahooシステム設定"],
	["registration_setting/shop_setting", "登録・設定方法/店舗設定"],
	["registration_setting/mail_setting", "登録・設定方法/メール設定"],
	["registration_setting/from_review_privilege_up_to_campaign_register", "登録・設定方法/レビュー特典～キャンペーン登録"],
	["registration_setting/user_account_setting", "登録・設定方法/ユーザーアカウント設定"],
	["registration_setting/password_setting", "登録・設定方法/パスワード設定"],
	["registration_setting/system_alert", "登録・設定方法/システムアラート設定"],
	["operation/top", "操作ガイド/TOP"],
	["operation/review_privilege", "操作ガイド/レビュー特典"],
	["operation/mail_setting", "操作ガイド/メール設定"],
	["operation/various_setup", "操作ガイド/各種設定"],
	["operation/csv_format", "操作ガイド/送り先CSV形式"],
]);
const noMarkdownTitles = [
	"use_guide/top",
	"registration_setting/use_flow"
];

type GuideParams = {
	'*'?: string;
}

export const useGuide = () => {
	const params = useParams<GuideParams>();
	const MARK_DOWN_PATH = "/assets/md/guide/";

	const paramPath = params['*'] || '';
	const lastPath = GUIDE_TITLES.has(paramPath) ? paramPath : "use_guide/top";
	const loadTargetPath = MARK_DOWN_PATH + (lastPath) + '.md';
	const [state] = useAppState();
	const basePath = state.params.basePath
	const folderPath = basePath + "/guide";

	const [isFullSize, setIsFullSize] = React.useState<boolean>(true);

	const isTop = lastPath === "use_guide/top";
	const isMarkdown = noMarkdownTitles.includes(lastPath) === false;

	const markDownTitle = GUIDE_TITLES.get(lastPath) || "利用ガイド情報が読み込めません";

	React.useEffect(() => {
		const handleResize = () => {
			setIsFullSize(window.innerWidth > 1200);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return {
		lastPath,
		loadTargetPath,
		basePath,
		folderPath,
		isFullSize,
		isTop,
		isMarkdown,
		markDownTitle,
	};
};
export default useGuide;