import { yahoo } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, InputPasswordOptional, SubmitButton } from '../../organisms/Form';

export const initValue = {
	yahooId: '',
	yahooPass: '',
}
export type FormValue = typeof initValue;

interface YahooLoginProps {
	storedValues: yahoo.GetShopResponse | null,
	methods: UseFormMethods;
	handleSave: SubmitHandler<FormValue>;
	handleInit: () => Promise<boolean>;
}

export const YahooLogin: React.FunctionComponent<YahooLoginProps> = (props) => {
	const { storedValues, methods, handleSave, handleInit } = props;
	const { errors } = methods;

	const handleClickInit = async () => {
		if (window.confirm("Yahoo!ストアクリエイターpro連携設定 を初期化します。よろしいですか？")) {
			await handleInit();
		}
	};

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>Yahoo!ストアクリエイターpro連携設定</FormLabel>
				<PageDesc>クーポンの発行やレビュー状況を監視するために必要な設定です。</PageDesc>
				<Form methods={methods} onSubmit={handleSave}>
					<RequiredDesc />
					<FormGroup>
						<FormLabel>ストアアカウント</FormLabel>
						<p>{storedValues?.yahooStoreAccount}</p>
					</FormGroup>
					<FormGroup>
						<FormLabel required>Yahoo!JAPAN ID</FormLabel>
						<Input type="text" name="yahooId" rules={{ required: 'Yahoo!JAPAN IDを入力してください' }} />
						<ErrorMessage name="yahooId" />
					</FormGroup>
					<FormGroup>
						<FormLabel required>Yahoo!JAPANパスワード</FormLabel>
						<InputPasswordOptional name="yahooPass" errors={errors} hide storedValue={storedValues?.yahooPass} />
						<ErrorMessage name="yahooPass" />
					</FormGroup>
					<div className="el_largeBtnWrap">
						<SubmitButton />
						<Button className="el_largeBtnBlueInv" type='button' onClick={handleClickInit}>初期化</Button>
					</div>
				</Form>
			</div>
		</>
	);
};
export default YahooLogin;