import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { contextListSelector } from './ListSelectorContext';

export interface ColumnProps {
	/** 見出し表示用 */
	children?: React.ReactNode;
	/** 見出し表示用 */
	header?: string;
	/** 表示カラム名 */
	field: string;
	/** 行表示用関数 */
	bodyTemplate?: (rowData: any) => React.ReactNode;
	/** 列表示用Style（grow, shrink, width, headAlign, bodyAlign 空白区切りで） */
	style: string;
	/** フィルター可否フラグ */
	filterable?: boolean;
	/** フィルター用フォームの表示位置 */
	filterFormAlign?: 'left' | 'right';
	/** フィルター用入力フォーム情報（未指定の場合、テキスト入力） */
	filterElement?: React.ReactNode | ((options: any) => React.ReactNode);
	/** フィルター用入力フォームのプレースホルダー */
	filterPlaceholder?: string;
	/** 行選択列指定フラグ */
	listSelector?: boolean;
	/** 並び替えのドラッグ対象指定フラグ */
	rowReorder?: boolean;
	/** 見出し指定Class名 */
	thClassName?: string;
	/** データ指定Class名 */
	tdClassName?: string;
	/** データ指定Class名生成用関数 */
	tdClassNameTemplate?: (rowData: any) => string;
	/** フィルター実行関数 */
	handleFilter?: () => void;
	/** フィルター値管理用Methods（useFormの戻り値） */
	methodFilter?: UseFormMethods;
	/** フィルター用フォーム表示中Column(field) */
	filteringColumn?: string;
	/** フィルター用フォーム表示切り替え関数 */
	setFilteringColumn?: (field: string | ((prev: string) => string)) => void;
}

export const Column: React.FC<ColumnProps> = (props) => {
	const context = React.useContext(contextListSelector);
	const checkAll = React.useRef<null|HTMLInputElement>(null);
	const { register } = props.methodFilter ?? {};

	React.useEffect(() => {
		if (!checkAll.current || !context) {
			return;
		}
		checkAll.current.checked = context.checkState.checked;
		checkAll.current.indeterminate = context.checkState.indeterminate;
	}, [checkAll.current, context?.checkState]);

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (!(ev.target instanceof HTMLElement) || ev.target.closest('th.bl_table_filterHead')) {
				return;
			}
			if (props.setFilteringColumn) {
				props.setFilteringColumn('');
			}
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleClickFilterMenu = () => {
		if (props.setFilteringColumn) {
			props.setFilteringColumn(prev => prev === props.field ? '' : props.field);
		}
	};
	const handleClickFilter = () => {
		if (props.handleFilter) {
			props.handleFilter();
		}
	};
	const handleClickFilterToClose = () => {
		handleClickFilter()
		if (props.setFilteringColumn) {
			props.setFilteringColumn('');
		}
	};

	const createFilterSection = (form: React.ReactNode, header = 'フィルタ') => {
		return (
			<>
				<p className="bl_tableSearchForm_ttl">{header}</p>
				<div className="el_searchInputWrap">
					<form>
						{form}
					</form>
				</div>
			</>
		);
	};

	const createTextFilterForm = (name: string, placeholder?: string) => {
		return <>
			<input type="text" name={name} placeholder={placeholder} ref={register} />
			<button className="el_searchBtn" type="button" onClick={handleClickFilterToClose} />
		</>;
	};

	const createFilter = () => {
		const filterOption = {
			createFilterSection,
			createTextFilterForm,
			register,
			handleFilterToClose: handleClickFilterToClose,
			handleFilter: handleClickFilter
		};
		let filter = typeof props.filterElement === 'function' ? props.filterElement(filterOption) : props.filterElement;

		if (!filter) {
			const textForm = createTextFilterForm(props.field, props.filterPlaceholder);
			filter = createFilterSection(textForm, '検索');
		}

		return filter;
	}

	const createHeader = () => {
		let header;
		if (props.listSelector && context) {
			header = <><input type="checkbox" className="el_checkMark" name="checkAll" ref={checkAll} onChange={context.handleChangeAll} /></>;
		} else {
			header = props.children || props.header;
		}
		return header;
	}

	const formClass = props.filterFormAlign == 'right' ? 'bl_tableSearchForm__right' : '';

	return (
		<>
			{props.filterable && <>
				<th className={`bl_table_filterHead ${props.thClassName} ${props.filteringColumn == props.field ? 'is_active' : ''}`}>
					<span className="bl_table_filterHeadTtl" onClick={handleClickFilterMenu}>{createHeader()}</span>
					<div className={`bl_tableSearchForm ${formClass}`}>
						{createFilter()}
					</div>
				</th>
			</>}
			{!props.filterable && <>
				<th className={props.thClassName}>{createHeader()}</th>
			</>}
		</>
	);
};
